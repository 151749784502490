<template>
  <v-btn
    class="elevation-0"
    color="#5CB7B1"
    @click="download"
  >
    <v-icon>mdi-download</v-icon>
  </v-btn>
</template>
<script>
import {mapGetters} from "vuex";
import {downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";

export default {
  props: {
    debitPayments: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      selected: "dt/getSelected"
    })
  },
  methods: {
    download() {
      this.$store.dispatch('dt/downloadPaymentsPdf', this.selected.id)
        .then((res) => {
          const name = getFilenameFromContentDisposition(res)
          downloadFile({data: res.data, type: 'application/pdf', name})
        }).catch(() => this.$error())
    }
  }
}
</script>