<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    cols="4"
  >
    <label>
      29  Орган въезда/выезда
      <v-menu offset-x>
        <template #activator="{ on, attrs }">
          <v-icon
            tabindex="-1"
            class="mx-1"
            small
            dense
            v-bind="attrs"
            v-on="on"
          >mdi-tune</v-icon>
        </template>
        <v-list>
          <v-list-item
            v-if="customFieldChecker(customsPoints, destination_item.customs_code, 'code')"
            @click="destination_item.customs_code = null"
          >
            Произвольное значение
          </v-list-item>
          <v-list-item
            v-else
            @click="destination_item.customs_code = ''"
          >
            Значение из справочника
          </v-list-item>
        </v-list>
      </v-menu>
    </label>
    <div class="d-flex">
      <v-text-field
        v-show="!customFieldChecker(customsPoints, destination_item.customs_code, 'code')"
        ref="dispatch_customs_code__custom"
        v-model="destination_item.customs_code"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @input="(v) => destination_item.customs_code = v === '' ? null : v"
      />
      <v-autocomplete
        v-show="customFieldChecker(customsPoints, destination_item.customs_code, 'code')"
        ref="dispatch_customs_code"
        v-model="destination_item.customs_code"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        item-text="text"
        item-value="code"
        :items="customsPoints"
        :filter="filterBySearchField"
        class="p-0"
      />
      <v-btn
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0"
        :class="{'mr-9' : index !== amount - 1}"
        @click="deleteDestination"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === amount - 1"
        color="transparent"
        min-width="36px"
        max-width="36px"
        class="elevation-0"
        @click="addDestination"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import {getCustomsNameByCode} from "@/helpers/catalogs"
import customFieldChecker from "@/mixins/custom-field-checker";

export default {
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, customFieldChecker],
  props: {
    item: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
    amount: {
      required: true,
      type: Number
    },
    base: {
      required: true,
      type: Object
    },
    shell: {
      required: true,
      type: Array
    },
    all: {
      required: true,
      type: Array
    },
    //
    customsPoints: {
      required: true,
      type: Array
    }
  },
  data(){
    return{
      destination_item: {
        customs_code: '',
        customs_name: ''
      }
    }
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `dt-destination-${this.index + 1}`
        for (let key in newVal) {
          if (key in this.destination_item) {
            this.destination_item[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    addDestination(){
      this.$emit('addDestination')
    },
    deleteDestination(){
      this.$emit('deleteDestination', this.index)
    },
    uploadData() {
      this.destination_item = {
        ...this.destination_item,
        customs_name: getCustomsNameByCode(this.customsPoints, this.destination_item.customs_code)
      }
      const destination_customs_json = [...this.all]
      destination_customs_json[this.index] = {
        ...this.destination_item
      }

      const about_transport = this.convertEmptyStringsToNull({
        ...this.base,
        transports_json: this.shell,
        destination_customs_json: [...destination_customs_json]
      })

      return this.$store.dispatch("dt/uploadBlockData", {
        name: "about_transport",
        value: about_transport,
      })
    }
  }
}
</script>
