<template>
  <v-row class="px-1">
    <v-col
      cols="2"
      class="pr-1"
    >
      <label>
        Кол-во мест
        <field-menu>
          <v-list-item @click="copyToAll('cargo_place_quantity', item.cargo_place_quantity )">
            Применить ко всем товарам
          </v-list-item>
          <v-list-item @click="copy('cargo_place_quantity', item.cargo_place_quantity, 'apply')">
            Применить к выбранным товарам
          </v-list-item>
        </field-menu>
      </label>
      <input-numeric
        ref="cargo_place_quantity"
        v-model="item.cargo_place_quantity"
        :fraction="0"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </v-col>
    <v-col
      cols="5"
      class="pr-1"
    >
      <label>
        Вид упаковки
        <field-menu>
          <v-list-item
            v-if="!customFieldCheckerNull(packages, item.package_type_name, 'name')"
            @click="item.package_type_name = '' "
          >
            Произвольное значение
          </v-list-item>
          <v-list-item
            v-else
            @click="item.package_type_name = null"
          >
            Значение из справочника
          </v-list-item>
          <v-list-item @click="copyToAll('package_type_name', item.package_type_name )">
            Применить ко всем товарам
          </v-list-item>
          <v-list-item @click="copy('package_type_name', item.package_type_name, 'apply')">
            Применить к выбранным товарам
          </v-list-item>
        </field-menu>
      </label>
      <v-text-field
        v-show="customFieldCheckerNull(packages, item.package_type_name, 'name')"
        ref="package_type_name__custom"
        v-model="item.package_type_name"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
      <v-autocomplete
        v-show="!customFieldCheckerNull(packages, item.package_type_name, 'name')"
        ref="package_type_name"
        v-model="item.package_type_name"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :items="packages"
        item-text="text"
        item-value="name"
        :filter="filterBySearchField"
      />
    </v-col>
    <v-col
      cols="2"
      class="px-2"
    >
      <label>
        Кол-во акц. марок
        <input-numeric
          ref="excise_quantity"
          v-model="item.excise_quantity"
          :fraction="0"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </label>
    </v-col>
    <v-col
      cols="3"
    >
      <label>Номер контейнера
        <span
          v-if="containersCount"
          class="font-weight-bold"
        >({{ containersCount }})
        </span>
        <field-menu>
          <v-list-item @click="copyToAll('containers', item.containers )">
            Применить ко всем товарам
          </v-list-item>
          <v-list-item @click="copy('containers', item.containers, 'apply')">
            Применить к выбранным товарам
          </v-list-item>
        </field-menu>
      </label>
      <v-textarea
        ref="containers"
        v-model.trim="item.containers"
        rows="1"
        outlined
        auto-grow
        dense
        hide-details
        background-color="white"
      />
    </v-col>
  </v-row>
</template>

<script>
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {mapGetters} from "vuex";
import {
  tnvedCodeChanged,
  updateGoodParameters,
} from "@/events/statistics";
import {normalizeFloat} from "@/helpers/math";
import cloneDeep from "lodash.clonedeep";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import {getMeasureUnitShortName} from '@/helpers/catalogs'
import InputNumeric from "@/components/ui/input-numeric.vue";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import {addPromiseInjector, readyToUpdateInjector} from "@/helpers/injectors";
import {getFirstSeparator} from "@/helpers/text";
import customFieldChecker from '@/mixins/custom-field-checker'
import FieldMenu from "@/components/documents/field-menu.vue";

export default {
  components: {FieldMenu, InputNumeric},
  mixins: [filterBySearchFieldMixin, HighlightFieldWareSector, copyFieldMixin, customFieldChecker],
  inject: {
    readyToUpdate: {
      from: readyToUpdateInjector
    },
    addPromise: {
      from: addPromiseInjector
    },
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      required: true,
      type: Object,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    item: {
      id: "",
      add_measure_unit_digit: "",
      add_measure_unit_letter: "",
      add_measure_unit_quantity: "",
      details_measure_unit_digit: "",
      details_measure_unit_letter: "",
      containers: "",
      excise_quantity: "",
      cargo_place_quantity: "",
      package_type_name: "",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      measure_units: "catalogs/getNsiMeasureUnits",
      selected_wares: "dt/getSelectedWares",
      packages: "catalogs/getNsiPackageTypes",
    }),
    containersCount() {
      if (!this.item.containers) return 0;
      const separator = getFirstSeparator(this.item.containers)
      return separator ? this.item.containers?.split(separator).filter(v => v).length : 0
    },
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    item: {
      handler() {
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeChanged.subscribe(this.onCodeChange);
    updateGoodParameters.subscribe(this.onParamsChange);
  },
  beforeDestroy() {
    tnvedCodeChanged.unsubscribe();
    updateGoodParameters.unsubscribe();
  },
  methods: {
    replaceFloatNumbers({value, prop, afterPoint = null}) {
      this.item[prop] = normalizeFloat({value, afterPoint});
    },
    onParamsChange({id, payload}) {
      if (id === this.info.id) {
        const {add_measure_unit_quantity} = payload;
        this.item.add_measure_unit_quantity = add_measure_unit_quantity;
        this.readyToUpdate();
      }
    },
    onCodeChange({id, code}) {
      if (id === this.info.id) {
        if (code) {
          const promise = new Promise((resolve, reject) => [
            this.$store.dispatch("tnved/fetchAddInfoByCode", code)
              .then((response) => {
                const {add_unit_measure} = response.data;
                this.item.add_measure_unit_digit = add_unit_measure;
                if (!add_unit_measure) this.item.add_measure_unit_quantity = "";
                resolve();
              })
              .catch((err) => reject(err)),
          ]);
          this.addPromise(promise);
        } else {
          const promise = new Promise((resolve) => {
            this.item.add_measure_unit_digit = null;
            this.item.add_measure_unit_quantity = null;
            resolve();
          });
          this.addPromise(promise);
        }
      }
    },
    updateEditableGoodsItem() {
      const payload = {
        ...this.item,
        add_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.add_measure_unit_digit),
        details_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.details_measure_unit_digit),
      }
      this.$emit("update", payload);
    },
    setFields() {
      const ware = cloneDeep(this.selected_wares[this.index])
      Object.keys(this.item).forEach(key => {
        if (key in ware) {
          this.item[key] = ware[key]
        }
      })
    },
  },
};
</script>
