<template>
  <v-col cols="5">
    <v-row>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>
          Фактурная стоимость
          <field-menu>
            <v-list-item @click="fillPriceWithWareDetailsCost">
              Пересчитать по стоимости товаров в списке
            </v-list-item>
            <v-list-item @click="fillPriceWithWareDetailsCostAllWares">
              Пересчитать по стоимости товаров в списке во всех товарах
            </v-list-item>
          </field-menu>
        </label>
        <input-numeric
          v-model="item.price"
          :fraction="2"
          :suffix="selected.about_wares.invoice_currency_letter"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="onPriceChange"
        />
      </v-col>
      <v-col cols="8">
        <cost-details
          no-data-title="Отсутствуют включения в фактурную стоимость"
          :items="item.invoice_cost_includings_json"
        />
      </v-col>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>42 Цена товара</label>
        <input-numeric
          v-model="item.invoice_cost"
          :suffix="selected.about_wares.invoice_currency_letter"
          :fraction="2"
          disabled
          background-color="grey lighten-2"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="4"
        class="grey--text d-flex align-end"
      >
        <p
          v-if="currencyRate"
          class="mb-3"
        >
          {{ currencyRate.currency_scale }} : {{ currencyRate.rate }}
        </p>
      </v-col>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>
          43 МОС
          <field-menu>
            <v-list-item @click="copyToAll('customs_cost_method', item.customs_cost_method)">
              Применить ко всем товарам
            </v-list-item>
            <v-list-item @click="copy('customs_cost_method', item.customs_cost_method, 'apply')">
              Применить к выбранным товарам
            </v-list-item>
            <v-list-item @click="copyToAll('customs_cost_method', null)">
              Очистить во всех товарах
            </v-list-item>
          </field-menu>
          <v-autocomplete
            v-model="item.customs_cost_method"
            :disabled="isExport"
            auto-select-first
            :items="costMethods"
            item-text="text"
            item-value="code"
            outlined
            dense
            hide-details="auto"
            :background-color="isExport ? 'grey lighten-2' : 'white'"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>
          Основа ТС
          <input-numeric
            :value="customsCostBase"
            :fraction="2"
            disabled
            suffix="BYN"
            outlined
            dense
            hide-details="auto"
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        cols="8"
      >
        <cost-details
          :items="item.customs_cost_includings_json"
          no-data-title="Отсутствуют включения в таможенную стоимость"
        />
      </v-col>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>45 Тамож. стоимость
          <field-menu>
            <v-list-item @click="recalculateCustomsCostAllWares">
              Рассчитать таможенную стоимость для всех товаров
            </v-list-item>
            <v-list-item @click="clearCustomsCost">
              Очистить таможенную стоимость для всех товаров
            </v-list-item>
            <v-list-item
              v-if="!manualCustomsCost"
              @click="manualCustomsCost = !manualCustomsCost"
            >
              Внести ТС вручную
            </v-list-item>
            <v-list-item
              v-if="manualCustomsCost"
              @click="recalculateCustomsCost"
            >
              Рассчитать ТС автоматически
            </v-list-item>
          </field-menu>
        </label>
        <input-numeric
          ref="customs_cost"
          v-model="item.customs_cost"
          :fraction="2"
          :disabled="!manualCustomsCost"
          suffix="BYN"
          outlined
          dense
          hide-details="auto"
          :background-color="manualCustomsCost ? 'white' : 'grey lighten-2'"
        />
      </v-col>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>
          46 Стат. стоимость
          <field-menu>
            <v-list-item @click="recalculateStatCost">
              Пересчитать стат. стоимость для товара
            </v-list-item>
            <v-list-item @click="recalculateAllCosts">
              Пересчитать ТС и стат. стоимость для документа
            </v-list-item>
          </field-menu>
          <input-numeric
            ref="statistical_cost"
            disabled
            :fraction="2"
            :value="item.statistical_cost"
            suffix="USD"
            outlined
            dense
            hide-details="auto"
            background-color="grey lighten-2"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="d-flex align-self-end"
      >
        <v-text-field
          ref="customs_index"
          outlined
          dense
          :value="customsIndex"
          disabled
          suffix="$/кг"
          background-color="grey lighten-2"
          hide-details="auto"
        />
      </v-col>
      <v-col
        v-if="isExport"
        cols="12"
      >
        <cost-details
          no-data-title="Отсутствуют включения в статистическую стоимость"
          :items="item.statistical_cost_includings_json"
        />
      </v-col>
      <v-col cols="6">
        <label> Специфическая ед.изм.
          <field-menu>
            <v-list-item @click="copySpecificMeasureToAll">
              Применить ко всем товарам
            </v-list-item>
            <v-list-item @click="copySpecificMeasure">
              Применить к выбранным товарам
            </v-list-item>
            <v-list-item
              :disabled="item.specific_measure_unit_digit !== $options.KG_DIGIT"
              @click="fillSpecificMeasureFromWeight"
            >
              Заполнить из веса для выбранных товаров
            </v-list-item>
            <v-list-item
              :disabled="!item.specific_measure_unit_digit"
              @click="fillSpecificMeasure"
            >
              Заполнить из доп.единицы для выбранных товаров
            </v-list-item>
          </field-menu>
        </label>
        <div class="d-flex mb-2">
          <input-numeric
            ref="specific_measure_unit_quantity"
            v-model="item.specific_measure_unit_quantity"
            style="width: 50%;"
            :fraction="2"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
          <v-autocomplete
            ref="specific_measure_unit_digit"
            v-model="item.specific_measure_unit_digit"
            auto-select-first
            item-text="shortDescription"
            item-value="code"
            :items="measureUnits"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :filter="filterBySearchField"
          />
        </div>
      </v-col>
      <v-col cols="6">
        <label>Подакцизный товар
          <field-menu>
            <v-list-item @click="copyExciseMeasureToAll">
              Применить ко всем товарам
            </v-list-item>
            <v-list-item @click="copyExciseMeasure">
              Применить к выбранным товарам
            </v-list-item>
            <v-list-item
              :disabled="!isFillQuantityAvailable"
              @click="fillExciseWithNetWeight"
            >
              Заполнить поле подакцизный товар
            </v-list-item>
          </field-menu>
        </label>
        <div class="d-flex mb-2">
          <input-numeric
            ref="excise_measure_unit_quantity"
            v-model="item.excise_measure_unit_quantity"
            :normalizer="exciseNormalizer"
            style="width: 50%;"
            :fraction="2"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
          <v-autocomplete
            ref="excise_measure_unit_digit"
            v-model="item.excise_measure_unit_digit"
            auto-select-first
            item-text="shortDescription"
            item-value="code"
            :items="measureUnits"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :filter="filterBySearchField"
            @change="normalizeExciseQuantity"
          />
        </div>
      </v-col>
    </v-row>
    <ware-calculated-payments
      v-if="item.ware_payment_json.length"
      :payments="warePaymentsPretty"
      @clear="clearPayments"
    />
    <template v-else>
      <ware-excises
        v-if="isAvailableExcisesOptions"
        :selected-excise="selectedExcise"
        :available-excises="availableExcises"
        @select="selectExcise"
      >
        <label>Акцизы</label>
        <field-menu>
          <v-list-item @click="copyExcise">
            Применить к выбранным товарам
          </v-list-item>
          <v-list-item @click="clearAndUpdatePaymentsOptions">
            Обновить список
          </v-list-item>
        </field-menu>
      </ware-excises>
      <ware-anti-dumpings
        v-if="availableAntiDumpings.length"
        :selected-anti-dumping="selectedAntiDumping"
        :available-anti-dumpings="availableAntiDumpings"
        @select="selectAntiDumping"
      >
        <label>Антидемпинговые меры </label>
        <field-menu>
          <v-list-item @click="copyAntidumpings">
            Применить к выбранным товарам
          </v-list-item>
          <v-list-item @click="clearAndUpdatePaymentsOptions">
            Обновить список
          </v-list-item>
        </field-menu>
      </ware-anti-dumpings>
      <ware-customs-duties
        v-if="availableCustomsDuties.length > 1"
        :selected-customs-duty="selectedCustomsDuty"
        :available-customs-duties="availableCustomsDuties"
        @select="selectCustomsDuty"
      >
        <label>Пошлины</label>
        <field-menu>
          <v-list-item @click="copyCustomsDuties">
            Применить к выбранным товарам
          </v-list-item>
          <v-list-item @click="clearAndUpdatePaymentsOptions">
            Обновить список
          </v-list-item>
        </field-menu>
      </ware-customs-duties>
    </template>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {getMeasureUnitShortName, KG_DIGIT} from '@/helpers/catalogs'
import {
  tnvedCodeChanged,
  updateGoodParameters,
} from "@/events/statistics";
import {
  getCustomsCost,
  getStatisticalCost,
  getWaresParamTotal,
  prettifyNumber,
  fetchCurrency,
  normalizeFloat, exciseQuantityAfterPoint
} from "@/helpers/math";
import cloneDeep from "lodash.clonedeep";
import formatDate from "@/mixins/format-date.js";
import {eventBus} from "@/bus.js";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {
  addPromiseInjector,
  isOpenInjector,
  readyToUpdateInjector, showDynamicComponentInjector,
  uploadDataInjector
} from "@/helpers/injectors";
import moment from "moment/moment";
import WareCalculatedPayments from "@/components/documents/ware/ware-calculated-payments.vue";
import {getAntiDumpingNeed, getAvailableExcise, getExciseNeed} from "@/helpers/ware-payments";
import WareExcises from "@/components/documents/ware/ware-excises.vue";
import WareAntiDumpings from "@/components/documents/ware/ware-anti-dumpings.vue";
import FieldMenu from "@/components/documents/field-menu.vue";
import CostDetails from "@/components/dt/goods/cost-details.vue";
import WareCustomsDuties from "@/components/documents/ware/ware-customs-duties.vue";
import {defaultAntiDumpingsJson, defaultCustomsDutyJson, defaultExciseJson} from "@/components/dt/goods/payments";
import {
  SPECIFIC_MEASURE_FROM_WEIGHT_TYPE,
  SPECIFIC_MEASURE_TYPE
} from "@/components/dt/goods/fill-with-utils";



export default {
  KG_DIGIT,
  components: {
    WareCustomsDuties,
    CostDetails, FieldMenu, WareAntiDumpings, WareExcises, WareCalculatedPayments, InputNumeric
  },
  mixins: [filterBySearchFieldMixin, formatDate, HighlightFieldWareSector, copyFieldMixin],
  inject: {
    readyToUpdate: {
      from: readyToUpdateInjector
    },
    addPromise: {
      from: addPromiseInjector
    },
    uploadData: {
      from: uploadDataInjector
    },
    isOpen:{
      from: isOpenInjector
    },
    showDynamicComponent:{
      from:showDynamicComponentInjector
    }
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      type: Object,
      required: true,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    wareDetails: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    availableExcises: {},
    availableAntiDumpings: [],
    availableCustomsDuties: [],
    manualCustomsCost: false,
    item: {
      id: null,
      invoice_cost: "",
      customs_cost: "",
      customs_cost_method: "",
      ware_payment_json: [],
      statistical_cost: null,
      net_weight: '',
      specific_measure_unit_digit: "",
      specific_measure_unit_letter: "",
      specific_measure_unit_quantity: "",
      excise_measure_unit_digit: "",
      excise_measure_unit_letter: "",
      excise_measure_unit_quantity: "",
      excise_json: defaultExciseJson(),
      antidumping_json: defaultAntiDumpingsJson(),
      customs_duty_json: defaultCustomsDutyJson(),
      customs_cost_includings_json: [],
      invoice_cost_includings_json: [],
      statistical_cost_includings_json:[],
      price: null,
    },
    currencyRate: null,
    customsCostBase: null,
  }),
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      currencies: "catalogs/getNsiCurrencies",
      selectedWares: "dt/getSelectedWares",
      costMethods: "catalogs/getNsiCustomsCostMethods",
      measureUnits: "catalogs/getNsiMeasureUnits",
      isImport40: "dt/isImport40",
      isExport: "dt/isExport"
    }),
    isAvailableExcisesOptions() {
      return !!this.availableExcises?.data?.length
    },
    warePaymentsPretty() {
      return this.item.ware_payment_json.map((payment) => {
        const basisPretty = prettifyNumber(payment.basis)
        const sumPretty = payment.sum ? prettifyNumber(Number.parseFloat(payment.sum).toFixed(2)) : '0'
        return {
          ...payment,
          basisPretty,
          sumPretty
        }
      })
    },
    selectedCustomsDuty() {
      return this.availableCustomsDuties.find(v => v.tarifId === this.item.customs_duty_json.duty_id) ?? null
    },
    selectedExcise() {
      return getAvailableExcise({
        availableExcises: this.availableExcises,
        transitExciseId: this.info.excise_json.transit_excise_id,
        exciseId: this.info.excise_json.excise_id
      })
    },
    isFillQuantityAvailable() {
      return ["КГ", "Т"].includes(this.selectedExcise?.measureUnitLetter)
    },
    selectedAntiDumping() {
      return this.availableAntiDumpings.find(v => v.tarifId === this.item.antidumping_json.antidumping_id) ?? null
    },
    customsIndex() {
      const value = (this.item.statistical_cost / this.item.net_weight).toFixed(2)
      if (!isNaN(value) && this.item.statistical_cost && this.item.net_weight) {
        return `${value}`
      }
      return "0"
    },
  },
  watch: {
    selectedWares: {
      handler() {
        const wareIdBeforeUpdate = this.item.id
        this.setFields();
        this.resetManualCustomsCostParam()
        this.updatePaymentsOptionsIfOpenAndIdChanged(wareIdBeforeUpdate)
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeChanged.subscribe(this.onNewCode);
    updateGoodParameters.subscribe(this.onParamsChange);
  },
  mounted() {
    this.watchCustomsCostBaseTriggers()
    this.watchCurrencyRateTriggers()
  },
  beforeDestroy() {
    tnvedCodeChanged.unsubscribe();
    updateGoodParameters.unsubscribe();
  },
  methods: {
    getNeedfulDate(){
      return this.selected.type_declaration.declaration_feature_date ?? this.selected.about_declaration.declaration_date
    },
    fillSpecificMeasure(){
      const params = {
        type: SPECIFIC_MEASURE_TYPE,
        filter:{
          measureUnitLetter:getMeasureUnitShortName(this.measureUnits, this.item.specific_measure_unit_digit)
        }
      }
      this.showDynamicComponent({
        params,
        component:() => import('@/components/dt/goods/fill-with-ware-details.vue')
      })
    },
    fillSpecificMeasureFromWeight(){
      const params = {
        type: SPECIFIC_MEASURE_FROM_WEIGHT_TYPE,
        filter:{
          measureUnitLetter:getMeasureUnitShortName(this.measureUnits, this.item.specific_measure_unit_digit)
        }
      }
      this.showDynamicComponent({
        params,
        component:() => import('@/components/dt/goods/fill-with-ware-details.vue')
      })
    },
    copySpecificMeasureToAll(){
      this.copyToAll([
        'specific_measure_unit_quantity',
        'specific_measure_unit_letter',
        'specific_measure_unit_digit'
      ], [
        this.item.specific_measure_unit_quantity,
        this.item.specific_measure_unit_letter,
        this.item.specific_measure_unit_digit
      ])
    },
    copySpecificMeasure(){
      this.copy([
        'specific_measure_unit_quantity',
        'specific_measure_unit_letter',
        'specific_measure_unit_digit'
      ], [
        this.item.specific_measure_unit_quantity,
        this.item.specific_measure_unit_letter,
        this.item.specific_measure_unit_digit
      ], null, 'apply', {
        specific_measure_unit_letter: this.item.specific_measure_unit_letter
      })
    },
    copyExciseMeasureToAll(){
      this.copyToAll([
        'excise_measure_unit_quantity',
        'excise_measure_unit_letter',
        'excise_measure_unit_digit'
      ], [
        this.item.excise_measure_unit_quantity,
        this.item.excise_measure_unit_letter,
        this.item.excise_measure_unit_digit
      ])
    },
    copyExciseMeasure(){
      this.copy([
        'excise_measure_unit_quantity',
        'excise_measure_unit_letter',
        'excise_measure_unit_digit'
      ], [
        this.item.excise_measure_unit_quantity,
        this.item.excise_measure_unit_letter,
        this.item.excise_measure_unit_digit
      ], 'apply',null, {
        excise_measure_unit_letter: this.item.excise_measure_unit_letter
      })
    },
    updatePaymentsOptionsIfOpenAndIdChanged(wareIdBeforeUpdate){
      if(this.isOpen() && wareIdBeforeUpdate !== this.item.id){
        this.preloadAvailablePaymentsOptions()
      }
    },
    forceUpload() {
      return this.uploadData()
        .catch((err) => {
          this.$error('Не удалось сохранить товар');
          throw err
        })
    },
    fillExciseWithNetWeight() {
      this.forceUpload()
        .then(() => this.$store.dispatch('dt/fillExciseUnitQuantityWithNetWeight')
          .catch((err) => {
            this.$error('Не удалось обновить значения')
            throw err
          })
        )
        .then(() => this.$info("Значения обновлены"));
    },
    normalizeExciseQuantity() {
      if (!this.item.excise_measure_unit_quantity) return;
      this.item.excise_measure_unit_quantity = this.exciseNormalizer(this.item.excise_measure_unit_quantity)
    },
    exciseNormalizer(value) {
      const unitLetter = getMeasureUnitShortName(this.measureUnits, this.item.excise_measure_unit_digit)
      const afterPoint = exciseQuantityAfterPoint(value, unitLetter)
      return normalizeFloat({value, afterPoint})
    },
    fetchCurrency,
    resetManualCustomsCostParam() {
      this.manualCustomsCost = false
    },
    clearCustomsCost() {
      this.copyToAll('customs_cost', null)
    },
    recalculateCustomsCost() {
      this.resetManualCustomsCostParam()
      this.updateCustomsCost()
      this.readyToUpdate()
    },
    watchCustomsCostBaseTriggers() {
      this.$watch((vm) => [
        vm.item.invoice_cost,
        vm.selected.about_declaration.declaration_date,
        vm.selected.about_wares.invoice_currency_letter
      ], () => this.updateCustomsCostBase(), {
        immediate: true
      })
    },
    watchCurrencyRateTriggers() {
      this.$watch((vm) => [
        vm.selected.about_declaration.declaration_date,
        vm.selected.about_wares.invoice_currency_letter
      ], () => this.fetchCurrencyRate(), {
        immediate: true
      })
    },
    updateCustomsCostBase(cost) {
      getCustomsCost.call(this, {
        date: this.getNeedfulDate(),
        code: this.selected.about_wares.invoice_currency_letter,
        cost: cost ?? this.item.invoice_cost
      }).then(customsCostBase => this.customsCostBase = customsCostBase)
    },
    fetchCurrencyRate() {
      this.fetchCurrency({
        date: this.getNeedfulDate(),
        code: this.selected.about_wares.invoice_currency_letter
      }).then((currency) => {
        if (currency) {
          this.currencyRate = currency
        }
      })
    },
    copyExcise() {
      const {measureUnitDigit, specificMeasureUnitDigit} = this.selectedExcise ?? {}
      this.copy([
        'excise_json',
        'excise_measure_unit_digit',
        'specific_measure_unit_digit'
      ],
      [
        this.item.excise_json,
        measureUnitDigit,
        specificMeasureUnitDigit
      ], 'apply', null, {
        tn_ved: this.info.tn_ved
      })
    },
    copyAntidumpings() {
      this.copy('antidumping_json', this.item.antidumping_json, 'apply', null, {
        tn_ved: this.info.tn_ved
      })
    },
    copyCustomsDuties(){
      this.copy('customs_duty_json', this.item.customs_duty_json, 'apply', null, {
        tn_ved: this.info.tn_ved
      })
    },
    fillPriceWithWareDetailsCostAllWares() {
      this.$store.dispatch('dt/recalculateInvoiceCostAllWares')
        .then(() => this.$info('Готово'))
        .catch(() => this.$error())
    },
    fillPriceWithWareDetailsCost() {
      this.item.price = getWaresParamTotal(this.wareDetails, 'invoice_cost')
      this.onPriceChange()
      this.readyToUpdate()
    },
    recalculateAllCosts() {
      this.uploadData()
        .then(() => this.$store.dispatch('dt/recalculateCustomsCost', this.selected.id))
        .then(() => this.$success("Готово"))
        .catch(() => this.$error())
    },
    recalculateStatCost() {
      this.updateStatCost()
        .then(() => this.readyToUpdate())
        .catch(() => this.$error())
    },
    updateStatCost() {
      const promise = new Promise((resolve, reject) => {
        getStatisticalCost
          .call(this, {
            date: this.getNeedfulDate(),
            code: this.selected.about_wares.invoice_currency_letter,
            cost: this.item.price,
          })
          .then((statCost) => {
            this.item.statistical_cost = statCost;
            return resolve(statCost);
          })
          .catch((err) => reject(err));
      });
      this.addPromise(promise)
      return promise;
    },
    getAllPreferences() {
      return ['preference_1', 'preference_2', 'preference_3', 'preference_4'].reduce((previous, current) => {
        return {
          ...previous,
          [current]: this.item[current]
        }
      }, {})
    },
    getKeysAndValues(preferences) {
      const keys = Object.keys(preferences)
      const values = Object.values(preferences)
      return {keys, values}
    },
    copyPreferencesToAll() {
      const preferences = this.getAllPreferences()
      const {keys, values} = this.getKeysAndValues(preferences)
      this.copyToAll(keys, values)
    },
    copyPreferences() {
      const preferences = this.getAllPreferences()
      const {keys, values} = this.getKeysAndValues(preferences)
      this.copy(keys, values, 'apply')
    },
    recalculateCustomsCostAllWares() {
      eventBus.$emit("recalculateCustomsCost");
    },
    onParamsChange({id, payload}) {
      if (id === this.item.id) {
        const {invoice_cost} = payload;
        this.item.invoice_cost = invoice_cost;
        this.updateCustomsCost()
          .finally(() => this.readyToUpdate());
      }
    },
    onNewCode({id, code}) {
      if (id === this.item.id) {
        this.updatePaymentsOptions(code);
        this.updateSpecificMeasure(code)
      }
    },
    getAntiDumpingsByTnved(code) {
      const tn_ved = code ?? this.info.tn_ved;
      return this.$store.dispatch('dt/getAntiDumpingsByTnved', {
        tn_ved,
        date: this.getDeclarationDate()
      })
    },
    getCustomsDuties(code) {
      const tn_ved = code ?? this.info.tn_ved;
      return this.$store.dispatch('dt/getCustomsDuties', {
        tn_ved,
        date: this.getDeclarationDate()
      })
    },
    getExcise(code) {
      const tn_ved = code ?? this.info.tn_ved;
      return this.$store
        .dispatch("dt/getAvailableExcise", {
          tn_ved,
          date: this.getDeclarationDate()
        })
    },
    preloadAvailablePaymentsOptions() {
      if (this.isEmptyCodeAndTnved() || this.isExport) return;
      Promise.all([
        this.getExcise(),
        this.getAntiDumpingsByTnved(),
        this.getCustomsDuties(),
      ]).then(([exciseResponse, antiDumpingsResponse, customsDutiesResponse]) => {
        this.setAvailableExcises(exciseResponse)
        this.setAvailableAntiDumpings(antiDumpingsResponse.data)
        this.setAvailableCustomsDuties(customsDutiesResponse.data)
      }).catch(() => this.$error('Ошибка загрузки платежей'))
    },
    isEmptyCodeAndTnved(code) {
      return !code && !this.info.tn_ved
    },
    updatePaymentsOptions(code) {
      if (this.isEmptyCodeAndTnved(code) || this.isExport) return;
      const promise = Promise.all([
        this.getExcise(code),
        this.getAntiDumpingsByTnved(code),
        this.getCustomsDuties(code)
      ]).then(([exciseResponse, antiDumpingsResponse, customsDutiesResponse]) => {
        if (exciseResponse?.data?.length) {
          this.setAvailableExcises(exciseResponse)
          this.clearAntiDumpings()
        } else if (antiDumpingsResponse?.data?.length) {
          this.setAvailableAntiDumpings(antiDumpingsResponse.data)
          this.clearExcise()
        } else {
          this.clearExcise()
          this.clearAntiDumpings()
        }
        if(customsDutiesResponse?.data?.length){
          this.setAvailableCustomsDuties(customsDutiesResponse.data)
        }else {
          this.clearCustomsDuties()
        }
      }).catch(() => this.$error('Ошибка загрузки платежей'))

      this.addPromise(promise)
    },
    setAvailableCustomsDuties(customsDuties) {
      this.availableCustomsDuties = customsDuties
    },
    setAvailableAntiDumpings(antiDumpings) {
      this.availableAntiDumpings = antiDumpings
    },
    setAvailableExcises(exciseResponse) {
      this.availableExcises = exciseResponse
    },
    getDeclarationDate() {
      const dateFormat = 'DD.MM.YYYY'
      return moment(this.selected.about_declaration.declaration_date, 'YYYY-MM-DD').isValid()
        ? moment(this.selected.about_declaration.declaration_date, 'YYYY-MM-DD').format(dateFormat)
        : moment().format(dateFormat)
    },
    clearAntiDumpings() {
      this.availableAntiDumpings = []
      this.item.antidumping_json = defaultAntiDumpingsJson()
      this.readyToUpdate()
    },
    clearCustomsDuties() {
      this.availableCustomsDuties = []
      this.item.customs_duty_json = defaultCustomsDutyJson()
    },
    clearExcise() {
      this.availableExcises = {}
      this.item.excise_json = defaultExciseJson()
      this.readyToUpdate();
    },
    onPriceChange() {
      this.clearIncludings()
      this.updateCustomsCost();
      this.updateInvoiceCost()
      this.updateStatCost()
    },
    clearIncludings() {
      this.item.invoice_cost_includings_json = []
      this.item.customs_cost_includings_json = []
      this.item.statistical_cost_includings_json = []
      this.readyToUpdate();
    },
    updateInvoiceCost() {
      this.item.invoice_cost = this.item.price
    },
    updateSpecificMeasure(code) {
      if (code) {
        const promise = this.$store.dispatch("tnved/fetchSpecificMeasureUnit", code)
          .then((response) => {
            const {code, short_description} = response.data;
            this.item.specific_measure_unit_digit = code;
            this.item.specific_measure_unit_letter = short_description;
          })
        this.addPromise(promise);
      } else {
        this.item.specific_measure_unit_quantity = null
      }
    },
    updateCustomsCost() {
      if (this.isExport) return;
      const promise = new Promise((resolve, reject) => {
        getCustomsCost
          .call(this, {
            date: this.getNeedfulDate(),
            code: this.selected.about_wares.invoice_currency_letter,
            cost: this.item.price,
          })
          .then((res) => {
            this.item.customs_cost = res;
            return resolve(res);
          })
          .catch((err) => reject(err));
      });
      this.addPromise(promise)
      return promise;
    },
    clearAndUpdatePaymentsOptions() {
      this.clearExcise()
      this.clearAntiDumpings()
      this.clearCustomsDuties()
      this.updatePaymentsOptions()
    },
    clearPayments() {
      this.item.ware_payment_json = [];
      this.readyToUpdate();
    },
    setFields() {
      const ware = cloneDeep(this.selectedWares[this.index]);
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
    },
    updateEditableGoodsItem() {
      this.readyToUpdate();
      const payload = {
        ...this.item,
        specific_measure_unit_letter: getMeasureUnitShortName(this.measureUnits, this.item.specific_measure_unit_digit),
        excise_measure_unit_letter: getMeasureUnitShortName(this.measureUnits, this.item.excise_measure_unit_digit)
      }
      this.$emit("update", payload);
    },
    selectCustomsDuty(value) {
      this.item.customs_duty_json = {
        duty_id: value.tarifId,
        rate_description: value.description,
        description: value.rateDescription,
      }
      this.readyToUpdate()
    },
    selectAntiDumping(value) {
      this.item.antidumping_json = {
        antidumping_need: getAntiDumpingNeed(value.tarifId),
        antidumping_id: value.tarifId,
        rate_description: value.description,
        description: value.rateDescription,
      }
      this.readyToUpdate()
    },
    selectExcise(value) {
      this.item.excise_json = {
        description: value.description,
        excise_id: value.tarifId,
        excise_need: getExciseNeed(value),
        rate_description: value.rateDescription,
        transit_excise_id: value.id,
      };
      this.item.excise_measure_unit_digit = value.measureUnitDigit
      this.item.specific_measure_unit_digit = value.factor2 ? value.specificMeasureUnitDigit : this.info.specific_measure_unit_digit
      this.readyToUpdate();
    },
  },
};
</script>
