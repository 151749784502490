<template>
  <div class="payments my-2">
    <slot />
    <v-radio-group
      :value="selectedExcise"
      hide-details="true"
      @change="(value) => $emit('select', value)"
    >
      <v-radio
        v-for="(radio, index) in availableExcises.data"
        :key="index"
        class="mb-2 mr-0"
        color="#000"
        :value="radio"
        dense
      >
        <template #label>
          <div class="text-body-2 d-flex justify-space-between">
            <v-tooltip
              v-if="radio.description.length > 30"
              max-width="400"
              bottom
            >
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{ radio.description.slice(0, 30) }}...
                </span>
              </template>
              {{ radio.description }}
            </v-tooltip>
            <span v-else>{{ radio.description }}</span>
            <span class="black--text font-weight-medium">{{
              radio.rateDescription
            }}</span>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import {nullableObjectValidator} from "@/helpers/props-validators";

export default {
  props: {
    availableExcises: {
      required: true,
      type: Object
    },
    selectedExcise: {
      required: true,
      validator: nullableObjectValidator
    }
  }
}
</script>