<template>
  <v-row
    class="statistics-box pb-4 ma-0"
    :class="{'blocked-box':disabledView}"
  >
    <v-col
      cols="12"
      class="d-flex align-center justify-space-between"
    >
      <span class="font-weight-bold"> 9 Лицо, ответственное за финансовое урегулирование
      </span>
      <slot />
    </v-col>
    <v-col
      cols="3"
      class="pb-0"
    >
      <p>{{ payer.payer_inn }}</p>
    </v-col>
    <v-col
      cols="4"
      class="pb-0"
    >
      <p>{{ payer.payer_name }}</p>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    payer: {
      required: true,
      type: Object
    }
  },
  computed:{
    ...mapGetters({
      disabledView:"dt/getVisibility"
    })
  },
}
</script>