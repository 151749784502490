<template>
  <v-row
    class="statistics-box pb-4 ma-0"
    :class="{
      'blocked-box':disabledView
    }"
  >
    <v-col
      cols="12"
      class="d-flex align-center justify-space-between"
    >
      <span class="font-weight-bold"> 2 Отправитель
      </span>
      <slot />
    </v-col>

    <v-col
      cols="3"
      class="pb-0"
    >
      <p>{{ sender.sender_inn }}</p>
    </v-col>
    <v-col
      cols="4"
      class="pb-0"
    >
      <p>{{ sender.sender_name }}</p>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    sender: {
      required: true,
      type: Object
    }
  },
  computed:{
    ...mapGetters({
      disabledView:"dt/getVisibility"
    })
  },
  methods: {
    open(){
      this.$emit('open')
    }
  }
}
</script>