const IMPORT = "ИМ"
const EXPORT = "ЭК"
export const TYPES = [
  {
    text:"Расходы на перевозку",
    value:1,
    available:[IMPORT, EXPORT]
  },
  {
    text:"Расходы на погрузку, разгрузку и проведение иных операций",
    value:2,
    available:[IMPORT, EXPORT]
  },
  {
    text:"Расходы которые не включены в цену: вознаграждение посредникам, расходы на тару, расходы на упаковку",
    value:3,
    available:[IMPORT, EXPORT]
  },
  {
    text: "Стоимость товаров и услуг, предоставленных покупателем бесплатно или по сниженной цене в связи с производством и продажей ввозимых товаров",
    value:4,
    available:[IMPORT, EXPORT]
  },
  {
    text: "Часть дохода, полученного в результате последующей продажи",
    value:5,
    available:[IMPORT, EXPORT]
  },
  {
    text: "Расходы на страхование",
    value:6,
    available:[IMPORT, EXPORT]
  },
  {
    text:"Лицензионные и иные подобные платежи",
    value:7,
    available:[IMPORT, EXPORT]
  },
  {
    text:"Скидка (вычитания из стоимости)",
    value:8,
    available:[IMPORT, EXPORT]
  },
]

export const INCLUDINGS = [
  {
    text:"Фактурная стоимость",
    value:1,
    available:[IMPORT]
  },
  {
    text:"Таможенная стоимость",
    value:2,
    available:[IMPORT]
  },
  {
    text:"Статистическая стоимость",
    value:3,
    available:[EXPORT]
  }
]
