<template>
  <copy-field-modal
    :headers="headers"
    :wares="preparedWares"
    :wares-params="waresParams"
    :show.sync="show"
  />
</template>
<script>
import CopyFieldModal from "@/components/documents/copy-field-modal.vue";
import {mapGetters} from "vuex";
import {DEFAULT_TABLE_HEADERS, prepareWaresParams} from "@/helpers/ware-copy-field";
import WareCopyFieldMixin from "@/mixins/ware-copy-field.mixin";

export default {
  DEFAULT_TABLE_HEADERS,
  components: {
    CopyFieldModal
  },
  mixins: [WareCopyFieldMixin],
  data() {
    return {
      headers: [
        {
          text: "№",
          value: "index",
          flex: 1,
          width: 60
        },
        {
          text: "Код товара",
          value: "tn_ved",
          flex: 1,
          width: 140
        },
        {
          text: "Описание",
          value: "combined_description",
          flex: 18,
          width: 800

        },
        {
          text: "Происх.",
          value: "origin_country_letter",
          flex: 1,
          width: 50
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      wares: "dt/getSelectedWares"
    })
  },
  methods: {
    onModalOpen(waresParams) {
      this.waresParams = prepareWaresParams(waresParams)
      const filteredWares = this.filterWares(this.wares, this.waresParams.filter)
      this.preparedWares = this.prepareWares(filteredWares)
      this.show = true
    },
    filterWares(wares = [], filter = {}) {
      return wares.filter(ware => Object.entries(filter).every(([key, value]) => ware[key] === value))
    },
    combinedDescription(item) {
      return [item?.description, item.specific_description].filter(v => v).join('; ').trim()
    },
    cut(description) {
      return description !== null && description.length > 180 ? description.slice(0, 180) + '...' : description
    },
    prepareWares(wares) {
      return wares.map((item, idx) => {
        const {
          id,
          tn_ved,
          add_tn_ved,
          origin_country_letter,
        } = item
        const combined_description = this.cut(this.combinedDescription(item))
        return {
          id,
          index: idx + 1,
          tn_ved,
          add_tn_ved,
          combined_description,
          origin_country_letter,
        }
      })
    }
  }
}
</script>
