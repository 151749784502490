<template>
  <v-btn
    color="#5cb7b1"
    dark
    tabindex="-1"
    @click="perform"
  >
    Контроль
  </v-btn>
</template>
<script>
import {
  toggleControlsNotification,
  toggleControlsPanel,
} from "@/events/common";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      requests: "dt/getRequests",
    }),
  },
  methods: {
    async perform() {
      await Promise.all(this.requests);
      const id = this.$route.params.id;
      this.$store
        .dispatch("dt/performControl", id)
        .then((res) => {
          toggleControlsPanel.trigger(true);
          if (!res.data?.length) {
            toggleControlsNotification.trigger();
          }
        })
        .catch((err) => {
          this.$snackbar({
            text:
              err.response.status === 401
                ? "Пользователь не авторизован"
                : "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
        });
    },
  },
};
</script>
