<template>
  <div>
    <v-container
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div
            class="statistics-box pa-4"
            :class="{
              'blocked-box':disabledView
            }"
          >
            <v-row>
              <v-col
                cols="10"
                class="pb-0"
              >
                <div class="font-weight-bold d-flex align-center mt-2">
                  Графа B
                </div>
              </v-col>
              <v-col
                cols="2"
                class="d-flex justify-end"
              >
                <download-payments :debit-payments="debitPayments" />
              </v-col>
              <v-col cols="3">
                <declarations-payments :debit-payments="debitPayments" />
              </v-col>
              <v-col offset="2">
                <debit-payments-table :debit-payments="debitPayments" />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DeclarationsPayments from "@/components/dt/graph-b/declarations-payments.vue";
import DebitPaymentsTable from "@/components/dt/graph-b/debit-payments-table.vue";
import {eventBus} from "@/bus";
import {UPDATE_DEBIT_PAYMENTS_TABLE} from "@/components/dt/graph-b/events";
import DownloadPayments from "@/components/dt/graph-b/download-payments.vue";


export default {
  components: {DownloadPayments, DebitPaymentsTable, DeclarationsPayments},
  data: () => ({
    debitPayments: []
  }),
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      user: "auth/getUser",
      disabledView: "dt/getVisibility",
      requests: "dt/getRequests"
    }),
  },
  watch: {
    "selected.id": {
      handler() {
        this.fetchPayments()
      }
    }
  },
  created() {
    eventBus.$on(UPDATE_DEBIT_PAYMENTS_TABLE, this.fetchPayments)
  },
  beforeDestroy() {
    eventBus.$off(UPDATE_DEBIT_PAYMENTS_TABLE, this.fetchPayments)
  },
  methods: {
    fetchPayments() {
      this.$store.dispatch('dt/getDebitPaymentsList').then(({data}) => this.debitPayments = data)
    }
  }
};
</script>
