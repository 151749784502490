<template>
  <div
    class="goods-description__action"
  >
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          tabindex="-1"
          min-width="36px"
          max-width="36px"
          color="#5CB7B1"
          v-on="on"
        >
          <v-icon>mdi-tune</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item

          :disabled="disabledView"
          @click="copyToAll(field, value)"
        >
          <v-list-item-title>Применить ко всем</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="disabledView"
          @click="copy(field, value, 'apply')"
        >
          <v-list-item-title>Применить к выбранным товарам</v-list-item-title>
        </v-list-item>
        <v-list-item @click="googleImage(value)">
          <v-list-item-title>
            <v-icon>mdi-magnify</v-icon>
            Поиск в  Google
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import {googleImage} from "@/helpers/text";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import {mapGetters} from "vuex";
export default {
  mixins:[copyFieldMixin],
  props:{
    field:{
      type:String,
      required:true
    },
    value:{
      validator:(v) => typeof v === "string" || v === null,
      required:true
    }
  },
  computed:{
    ...mapGetters({
      disabledView:"dt/getVisibility",
    })
  },
  methods:{
    googleImage,

  }
}
</script>
<style scoped>
.goods-description__action {
  position: absolute;
  right: 1%;
  top: 10px;
  z-index: 2;
}
</style>
