<template>
  <v-col
    v-if="checkType()"
    cols="6"
    fluid
    order="1"
  >
    <v-row
      class="statistics-box pb-4 ma-0"
    >
      <v-col
        cols="12"
        class="pb-0"
      >
        <div class="font-weight-bold d-flex align-center mt-2">
          48 Отсрочка платежей
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                tabindex="-1"
                class="mx-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >
                mdi-tune
              </v-icon>
            </template>                
          </v-menu>
        </div>
      </v-col>
      <v-col
        cols="12"
        style="padding: 0px;"
      >
        <div>
          <v-col
            v-if="hasInformation"
            cols="12"
          >                 
            <deferred-payment-item
              v-for="(item, index) in deferred_payments_json"
              :key="index"
              :item="item"
              :index="index"
              :base="deferred_payment"
              :all="deferred_payments_json"
              :amount="deferred_payments_json.length"
              @addNewDeferredPayment="addNewDeferredPayment"
              @deleteDeferredPayment="deleteDeferredPayment"
            />
          </v-col>
          <v-btn
            v-else
            text
            @click="addNewDeferredPayment"
          >
            <v-icon small>
              mdi-plus
            </v-icon>
            Добавить отсрочку платежей
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import DeferredPaymentItem from "./deferred-payment-item.vue";

export default {
  components: { DeferredPaymentItem },
  data: () => ({
    collapsed: false,
    deferred_payment: {
      declaration_id: null,
    },
    deferred_payments_json: [],
    type_declaration: {
      declaration_kind_code: null,
      customs_procedure: null,
    },
  }),
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
    }),
    hasInformation() {
      return !!this.deferred_payments_json.length;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  methods: {    
    setFields() {
      const deferred_payment = this.selected.deferred_payment
      const type_declaration = this.selected.type_declaration

      this.deferred_payments_json = this.selected.deferred_payment.deferred_payments_json

      for (let key in deferred_payment) {
        if (key in this.deferred_payment) {
          this.deferred_payment[key] = deferred_payment[key];
        }
      }

      for (let key in type_declaration) {
        if (key in this.type_declaration) {
          this.type_declaration[key] = type_declaration[key];
        }
      }
    },
    deleteDeferredPayment(i){
      this.deferred_payments_json.splice(i, 1)
      this.uploadData()
    },
    addNewDeferredPayment() {
      this.deferred_payments_json.push({
        code: null,        
        date: null
      })
      this.uploadData()
    },
    uploadData(){
      const deferred_payment = {
        ...this.deferred_payment,
        deferred_payments_json: [
          ...this.deferred_payments_json,
        ],
      }

      return this.$store.dispatch("dt/uploadBlockData", {
        name: "deferred_payment",
        value: deferred_payment,
      }).then(() => {
        this.$snackbar({text:"Обновлено [Отсрочка платежей]",color:"green", top:false, right: false});
      })
    },
    checkType(){
      return (this.type_declaration.customs_procedure === '40' && this.type_declaration.declaration_kind_code === 'ИМ')
    }
  },
};
</script>
