<template>
  <v-col
    cols="6"
    class="pb-4 px-2"
  >
    <div class="d-flex">
      <!-- <v-text-field
        v-model="quote.quantity"
        outlined
        style="width: 50%;"
        dense
        class="mr-2"
        hide-details="auto"
        background-color="white"
        @input="(v) => (item.add_measure_unit_quantity = v.replace(',', '.'))"
        @change="changed"
      /> -->
      <input-numeric
        v-model="quote.quantity"
        style="width: 50%;"
        :fraction="2"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @change="changed"
      />
      <v-autocomplete
        v-model="quote.measure_unit_digit"
        auto-select-first
        item-value="code"
        :items="measure_units"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :filter="filterBySearchField"
        item-text="shortDescription"
        @change="changed"
      />
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import { getMeasureUnitShortName } from '@/helpers/catalogs'
import InputNumeric from "@/components/ui/input-numeric.vue";

export default {
  components: {InputNumeric},
  mixins: [filterBySearchFieldMixin],
  props:{
    index:{
      required:true,
      type:Number
    },
    visited:{
      type:Boolean,
      required:true
    },
    item:{
      required:true,
      type:Object
    },
    length:{
      required:true,
      type:Number,
    },
  },
  data:() => ({
    quote: {
      quantity:"",
      measure_unit_digit:"",
      measure_unit_letter:"",
    }
  }),
  computed: {
    ...mapGetters({
      measure_units: "catalogs/getNsiMeasureUnits",
    })
  },
  watch: {
    quote: {
      handler(nv){
        if(this.visited) this.changed(nv)
      },
      deep: true
    }
  },
  created(){
    this.quote = {...this.item}
  },
  methods:{
    changed(){
      const value = {
        ...this.quote,
        measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.quote.measure_unit_digit )
      }
      this.$emit('change', {index: this.index, value})
    }
  }
}
</script>
