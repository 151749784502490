<template>
  <v-row>
    <v-col cols="11">
      <v-row class="rounded grey lighten-2 text-body-2 mb-0">
        <v-col cols="2">
          <span class="font-weight-bold teal--text">{{ item.code }} </span>
        </v-col>
        <v-col
          cols="8"
          class="text-end"
        >
          {{ prettySum }}
          <template v-if="remainToBePaid">
            /
            <span :class="remainToBePaid > 0 ?'red--text': 'green--text'">{{ remainToBePaidPaidPretty }}</span>
          </template>
        </v-col>
        <v-col cols="2">
          {{ item.currency_letter }}
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="1"
      class="py-0"
    >
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            class="elevation-0 mt-1"
            color="teal lighten-1"
            width="40"
            min-width="40"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="debit">
            <v-icon>mdi-currency-usd</v-icon>Списать ПП
          </v-list-item>
          <v-list-item @click="add">
            <v-icon>mdi-plus</v-icon>Добавить ПП
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import {toPrettyFloat} from "@/helpers/math";

export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
    debitSum: {
      required: true,
      type: Object
    }
  },
  computed: {
    remainToBePaid() {
      const value = this.item.sum - (this.debitSum[this.item.code] ?? 0)
      return +value.toFixed(2)
    },
    remainToBePaidPaidPretty() {
      return toPrettyFloat(this.remainToBePaid)
    },
    prettySum() {
      return toPrettyFloat(this.item.sum)
    }
  },
  methods: {
    remainsForModal() {
      return this.remainToBePaid > 0 ? this.remainToBePaid : 0;
    },
    add() {
      this.$emit("add", {item: this.item, remains: this.remainsForModal()})
    },
    debit() {
      this.$emit("debit", {item: this.item, remains: this.remainsForModal()})
    }
  }
}
</script>
<style></style>