<template>
  <v-col
    v-if="checkType()"
    cols="6"
    fluid
    order="2"
  >
    <v-row
      class="statistics-box pb-4 ma-0"
    >
      <v-col
        cols="12"
        class="pb-0"
      >
        <div class="font-weight-bold d-flex align-center mt-2">
          52 Гарантия
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                tabindex="-1"
                class="mx-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >
                mdi-tune
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="hideTooltips = !hideTooltips">
                {{ hideTooltips ? "Включить" : "Отключить" }} подсказки
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col
        cols="12"
        style="padding: 0px;"
      >
        <div>
          <v-col
            v-if="hasInformation"
            cols="12"
          >                 
            <warranty-item
              v-for="(item, index) in guarantees_json"
              :key="index"
              :banks="banks"
              :item="item"
              :index="index"
              :base="guarantee"
              :all="guarantees_json"
              :amount="guarantees_json.length"
              :guarantee-types="guaranteeTypes"
              :hide-tooltips="hideTooltips"
              @addNewWarranty="addNewWarranty"
              @deleteWarranty="deleteWarranty"
            />
          </v-col>
          <v-btn
            v-else
            text
            @click="addNewWarranty"
          >
            <v-icon small>
              mdi-plus
            </v-icon>
            Добавить гарантию
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import WarrantyItem from "./warranty-item.vue";
// import { getScrollOffset } from "@/helpers/control";
// import { guarantees as onGuarantee } from "@/events/statistics/control";

export default {
  components: { WarrantyItem },
  data: () => ({
    collapsed: false,
    banks: [],
    guarantee: {
      declaration_id: null,
    },
    guarantees_json: [],
    type_declaration: {
      declaration_kind_code: null,
      customs_procedure: null,
    },
    hideTooltips: true,
  }),
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      guaranteeTypes: "catalogs/gethNsiGuaranteeTypes",
    }),
    hasInformation() {
      return !!this.guarantees_json.length;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  created() {
    this.loadBankBIC();
  },
  methods: {
    loadBankBIC() {
      const uninterceptedAxiosInstance = axios.create();
      return uninterceptedAxiosInstance
        .get("https://www.nbrb.by/api/bic")
        .then((res) => {
          this.banks = res.data.map((item) => {
            return {
              ...item,
              search: `${item.CDBank} - ${item.NmBankShort}`,
            };
          });
        });
    },
    setFields() {
      const guarantee = this.selected.guarantee
      const type_declaration = this.selected.type_declaration

      this.guarantees_json = this.selected.guarantee.guarantees_json

      for (let key in guarantee) {
        if (key in this.guarantee) {
          this.guarantee[key] = guarantee[key];
        }
      }

      for (let key in type_declaration) {
        if (key in this.type_declaration) {
          this.type_declaration[key] = type_declaration[key];
        }
      }
    },
    deleteWarranty(i){
      this.guarantees_json.splice(i, 1)
      this.uploadData()
    },
    addNewWarranty() {
      this.guarantees_json.push({
        code: null,
        sum: null,
        currency_letter: null,
        number: null,
        date: null,
        inn: null,
        bank_id: null
      })
      this.uploadData()
    },
    uploadData(){
      const guarantee = {
        ...this.guarantee,
        guarantees_json: [
          ...this.guarantees_json,
        ],
      }

      return this.$store.dispatch("dt/uploadBlockData", {
        name: "guarantee",
        value: guarantee,
      }).then(() => {
        this.$snackbar({text:"Обновлено [Гарантия]",color:"green", top:false, right: false});
      })
    },
    checkType(){
      return (this.type_declaration.customs_procedure === '40' && this.type_declaration.declaration_kind_code === 'ИМ')
    }
  },
};
</script>