<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-col
      cols="12"
    >
      <v-row
        class="statistics-box pb-4"
        :class="{
          'blocked-box':disabledView
        }"
      >
        <v-col
          cols="9"
          class="d-flex align-center "
        >
          <span class="font-weight-bold">Сведения из договора</span>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="search"
            class="ml-16"
            :loading="loading"
            :readonly="loading"
            :items="importers"
            item-text="own_name"
            :filter="filterBySearchField"
            placeholder="Поиск в справочнике"
            auto-select-first
            return-object
            append-icon="mdi-magnify"
            background-color="white"
            dense
            outlined
            hide-details="true"
            @update:search-input="debouncedFetchImporters"
          />
        </v-col>
        <v-col
          class="pr-0"
          cols="2"
        >
          <label>
            11 Торгующая страна
            <v-autocomplete
              v-model="deal_details_json.trade_country_letter"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :items="countries"
              item-text="text"
              item-value="letterCode"
              :filter="filterBySearchField"
              @change="setRegionAuto($event)"
            />
          </label>
        </v-col>
        <v-col
          cols="1"
        >
          <label>
            Регион
            <v-autocomplete
              ref="trade_country_region"
              v-model="deal_details_json.trade_country_region"
              auto-select-first
              :items="regionsForSelectedCountry"
              item-text="text"
              item-value="code"
              outlined
              dense
              background-color="white"
              hide-details="auto"
              :filter="filterBySearchField"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>24 Характер сделки
            <v-menu offset-x>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mx-1"
                  tabindex="-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="openDealTypes">
                  Поиск в справочнике
                </v-list-item>
              </v-list>
            </v-menu>
          </label>
          <div class="d-flex">
            <v-autocomplete
              v-model="deal_details_json.deal_operation_code"
              :items="dealCharacters"
              item-text="code"
              item-value="code"
              auto-select-first
              outlined
              dense
              background-color="white"
              hide-details="auto"
              class="mr-1"
            />
            <v-autocomplete
              v-model="deal_details_json.deal_feature_code"
              :items="features"
              item-text="code"
              item-value="code"
              auto-select-first
              outlined
              dense
              background-color="white"
              hide-details="auto"
              class="mr-1"
            />
          </div>
        </v-col>
        <v-col
          cols="5"
          class="pr-0"
        >
          <label>
            Продавец
            <field-menu>
              <v-list-item
                @click="openCatalogInNewTab('noResidents')"
              >
                Открыть справочник нерезидентов
              </v-list-item>
            </field-menu>
          </label>
          <v-text-field
            v-model="deal_details.seller_name"
            outlined
            dense
            background-color="white"
            class="mr-2"
            hide-details="auto"
            @change="clearSellerId"
          />
        </v-col>
        <v-col>
          <v-btn
            dense
            height="40px"
            class="elevation-0 mt-6"
            @click="show = true"
          >
            <v-icon>mdi-plus</v-icon>
            Добавить продавца
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <archive-seller
      :show.sync="show"
      :name="deal_details.seller_name"
      @save="saveToArchive"
    />
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import ArchiveSeller from './archive-seller.vue';
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {getCountryDigitCodeByLetterCode, openCatalogInNewTab} from "@/helpers/catalogs";
import {getCountryNameByCode} from "@/helpers/catalogs";
import {showCatalog, catalogValueSelected} from "@/events/statistics";
import {deal_details as onDealDetails} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";
import FieldMenu from "@/components/documents/field-menu.vue";
import {debounce} from "@/helpers/inputs";

export default {
  components: {FieldMenu, ArchiveSeller},
  mixins: [blockAutoUpdate, filterBySearchFieldMixin],
  data() {
    return {
      block_id: 'dt-deal-details',
      deal_details: {
        declaration_id: null,
        seller_name: null,
        seller_id: null
      },
      deal_details_json: {
        deal_feature_code: '',
        deal_operation_code: '',
        trade_country_letter: '',
        trade_country_name: '',
        trade_country_region: '',
        delivery_type_code: ''
      },
      loading: false,
      importers: [],
      search: null,
      show: false
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      selected: 'dt/getSelected',
      countries: "catalogs/getNsiCountries",
      dealCharacters: "catalogs/getNsiDealCharactersDT",
      features: "catalogs/getNsiDealFeaturesDT",
      delivery: "catalogs/getNsiTypesOfDelivery",
      regions: "catalogs/getNsiRegions",
      disabledView: "dt/getVisibility"
    }),
    divisionId() {
      return this.user?.user?.activeDivisionId;
    },
    regionsForSelectedCountry() {
      if (this.deal_details_json.trade_country_letter) {
        const code = getCountryDigitCodeByLetterCode(this.countries, this.deal_details_json.trade_country_letter)
        return this.regions.filter(i => i.countryDigit === code || i.countryDigit === null)
      }
      return this.regions
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields()
      },
      deep: true
    },
    search(seller) {
      if (seller && Object.keys(seller).length) {
        this.deal_details.seller_name = seller.name
        this.deal_details.seller_id = seller.id
        this.search = null;
        this.importers = [];
        this.uploadData().then(() => {
          this.$snackbar({text: "Обновлено [Сведения из договора]", color: "green", top: false, right: false});
        }).catch(() => {
          this.$snackbar({text: "Ошибка", color: "red", top: false, right: false});
        })
      }
    },
  },
  created() {
    this.debouncedFetchImporters = debounce(this.fetchImporters)
    onDealDetails.subscribe(this.highlightField)
    catalogValueSelected.subscribe(this.updatesResolver, [
      'deal_details_json.dealtypes'
    ])
  },
  beforeDestroy() {
    onDealDetails.unsubscribe()
    catalogValueSelected.unsubscribe()
  },
  methods: {
    openCatalogInNewTab,
    highlightField,
    clearSellerId(){
      this.deal_details.seller_id = null
    },
    saveToArchive(seller) {
      const payload = {
        id: null,
        division_id: this.divisionId,
        own_name: seller.own_name,
        name: seller.own_name,
        inn: seller.inn,
        country_letter: seller.country_letter,
        country_name: getCountryNameByCode(this.countries, seller.country_letter) || null,
        region: seller.region,
        city: seller.city,
        street: seller.street,
        house: seller.house,
        room: seller.room,
        postal_index: seller.postal_index,
      };

      this.$store
        .dispatch("division/saveNoResidentRaw", payload)
        .then(() => this.$info("Справочник обновлен"))
        .catch((err) => {
          const msg = err.response.status === 400 ? err.response.data || "Ошибка" : "Ошибка"
          this.$error(msg)
        });
    },
    debouncedFetchImporters(){},
    fetchImporters(value) {
      if (value && value.length > 3) {
        this.loading = true;
        this.$store
          .dispatch("division/searchNoResident", value)
          .then((res) => {
            this.loading = false;
            const items = res.data.map((i) => {
              return {
                ...i,
                search: `${i.name}-${i.own_name}-${i.unp}-${i.city}-${i.street}`,
              };
            });
            this.importers = [...items];
          })
          .finally(() => (this.loading = false));
      }
    },
    updatesResolver({code, field, catalog = null}) {
      if (code && field) {
        if (field === 'deal_details_json.dealtypes') {
          this.deal_details_json[catalog] = code
        }
        this.fieldsBeenVisitedByUser()
        this.triggerOnChange()
      }
    },
    openDealTypes() {
      showCatalog.trigger({
        type: "dealtypes",
        search: "",
        field: "deal_details_json.dealtypes",
      });
    },
    setRegionAuto(e) {
      const available = ['RU', 'BY', 'KZ']
      if (available.includes(e)) {
        return
      } else {
        this.deal_details_json.trade_country_region = '000'
      }
    },
    setFields() {
      const deal_details = this.selected.deal_details
      const deal_details_json = this.selected.deal_details.deal_details_json

      Object.keys(this.deal_details).forEach(key => {
        if (key in deal_details) {
          this.deal_details[key] = deal_details[key]
        }
      })

      Object.keys(this.deal_details_json).forEach(key => {
        if (key in deal_details_json) {
          this.deal_details_json[key] = deal_details_json[key]
        }
      })
    },
    uploadData() {
      const deal_details = this.convertEmptyStringsToNull({
        ...this.deal_details,
        deal_details_json: {
          ...this.deal_details_json,
          trade_country_name: getCountryNameByCode(this.countries, this.deal_details_json.trade_country_letter) || null,
        }
      })
      return this.$store.dispatch("dt/uploadBlockData", {
        name: "deal_details",
        value: deal_details,
      });
    }
  }
}
</script>
