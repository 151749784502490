<template>
  <v-row class="rounded grey lighten-2 text-body-2 font-weight-bold mb-0 mr-4">
    <v-col cols="1">
      Итого
    </v-col>
    <v-col
      cols="9"
      class="text-end"
    >
      {{ paymentsSum }}
    </v-col>
    <v-col cols="1">
      BYN
    </v-col>
  </v-row>
</template>
<script>
import {toPrettyFloat} from "@/helpers/math";

export default {
  props:{
    payments:{
      required:true,
      type:Array
    }
  },
  computed:{
    paymentsSum(){
      const value = this.payments.reduce((previousValue, currentValue) => {
        return previousValue + +currentValue.sum
      },0)
      return toPrettyFloat(value)
    }
  }

}
</script>