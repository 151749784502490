<template>
  <div v-if="allowed">
    <v-btn
      :color="currentClientDivision ? 'teal': 'black'"
      text
      small
      @click="openUserDialog"
    >
      <v-icon small>
        mdi-share-variant
      </v-icon>
      <span class="px-1">Поделиться</span>
    </v-btn>
    <common-dialog
      :show="show"
      :width="800"
      @close="closeDialog"
    >
      <template #body>
        <span v-if="currentClientDivision || discoveredDivision === null">
          Убрать доступ к данному документу ?
        </span>
        <span v-else>
          Предоставить доступ организации <b>{{ unp }}</b> к данному документу ?
        </span>
      </template>
      <template #footer>
        <div class="d-flex justify-end mt-4">
          <v-btn
            v-if="currentClientDivision || discoveredDivision === null"
            dark
            color="red"
            elevation="0"
            @click="setDocumentAccess(null)"
          >
            Убрать доступ
          </v-btn>
          <v-btn
            v-else
            dark
            color="teal lighten-2"
            elevation="0"
            @click="setDocumentAccess(discoveredDivision)"
          >
            Разрешить доступ
          </v-btn>
        </div>
      </template>
    </common-dialog>
  </div>
</template>
<script>


import CommonDialog from "@/components/shared/common-dialog.vue";
import {isBTS} from "@/helpers/risks";
import {mapGetters} from "vuex";
import {uploadDataInjector} from "@/helpers/injectors";

export default {
  components: {CommonDialog},
  inject: {
    uploadData: {
      from: uploadDataInjector
    }
  },
  props: {
    unp: {
      required: false,
      validator: (v) => typeof v === "string" || v === null,
      default: null,
    }
  },
  data() {
    return {
      show: false,
      discoveredDivision: null,
    }
  },
  computed: {
    ...mapGetters({
      selected: "dt/getSelected"
    }),
    currentClientDivision() {
      return this.selected.client_division_id
    },
    allowed() {
      return isBTS()
    }
  },
  methods: {
    getDivisionByUnp() {
      return this.$store.dispatch('auth/fetchDivisionIdByUnp', this.unp)
    },
    showDialog(){
      this.show = true
    },
    setDiscoveredDivision(id){
      this.discoveredDivision = id
    },
    openUserDialog() {
      this.getDivisionByUnp(this.unp)
        .then((res) => {
          this.setDiscoveredDivision(res.data)
          this.showDialog()
        })
        .catch((err) => {
          this.$error(err.response.data)
          if(this.currentClientDivision){
            this.showDialog()
          }
        })
    },
    closeDialog() {
      this.show = false
    },
    setDocumentAccess(division) {
      const msg = division ? "Доступ предоставлен" : "Доступ заблокирован"
      this.uploadData()
        .then(() => this.$store.dispatch('dt/setClientDivisionId', division))
        .then(() => this.$store.commit('dt/SET_CLIENT_DIVISION_ID', division))
        .then(() => this.$success(msg))
        .then(() => this.closeDialog())
        .catch(() => this.$error())
    }
  }
}
</script>
