<template>
  <v-overlay :value="show">
    <v-card
      class="mx-auto pa-5"
      max-width="780px"
      color="white"
      light
    >
      <v-card-title class="mb-2 px-0">
        Cохранить продавца в справочник
      </v-card-title>

      <v-row>
        <v-col cols="2">
          <v-autocomplete
            v-model="seller.country_letter"
            auto-select-first
            :filter="filterBySearchField"
            label="Страна"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :items="countries"
            item-text="letterCode"
            item-value="letterCode"
          />
          <!-- <v-text-field
            v-model="seller.country_letter"
            label="Страна"
            dense
            outlined
            hide-details="true"
          /> -->
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="seller.inn"
            label="УНП (ИНН И т.п.)"
            dense
            outlined
            hide-details="true"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="seller.own_name"
            label="Наименование"
            dense
            outlined
            hide-details="true"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="seller.postal_index"
            label="Индекс"
            dense
            outlined
            hide-details="true"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="seller.region"
            label="Область/Район"
            dense
            outlined
            hide-details="true"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="seller.city"
            label="Город/Деревня"
            dense
            outlined
            hide-details="true"
          />
        </v-col>
        <v-col cols="7">
          <v-text-field
            v-model="seller.street"
            label="Улица"
            dense
            outlined
            hide-details="true"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="seller.house"
            label="Дом"
            dense
            outlined
            hide-details="true"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="seller.room"
            label="Номер помещ."
            dense
            outlined
            hide-details="true"
          />
        </v-col>
      </v-row>

      <v-card-actions class="mt-4 px-0 justify-between">
        <v-btn
          dark
          color="red"
          class="px-5"
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          dark
          color="green"
          class="px-5"
          @click="save"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import { mapGetters } from 'vuex';
import { filterBySearchFieldMixin } from "@/mixins/catalogs";

export default {
  mixins: [filterBySearchFieldMixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    name: {
      default: '',
      type:String
    }
  },
  data: () => ({
    seller: {
      country_letter: null,
      inn: null,
      own_name: null,
      postal_index: null,
      region: null,
      city: null,
      street: null,
      house: null,
      room: null
    }
  }),
  computed:{
    ...mapGetters({
      selected:'dt/getSelected',
      countries: "catalogs/getNsiCountries",
    })
  },
  watch:{
    show(nv){
      if(nv === false){
        this.unset()
      }else{
        this.seller.own_name = this.name
      }
    }
  },
  methods: {
    unset(){
      this.seller = {
        country_letter: null,
        inn: null,
        own_name: null,
        postal_index: null,
        region: null,
        city: null,
        street: null,
        house: null,
        room: null
      }
    },
    close() {
      this.$emit("update:show", false);
    },
    save() {
      this.$emit("save", this.seller);
      this.close();
    },
  },
};
</script>